import React, { useEffect, useState } from 'react';
import { Card, CardContent, CircularProgress, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { User } from "../../../../ifaces";
import { APIPost, APIPut, APIGet } from "../../../../FetchBuilder";
import UnitagContainedButton from "../../../../utils/unitagContainedButton";
import UnitagPapernumber from "../../../../utils/unitagPaperNumber"
import ColorChip from '../../../../utils/colorChip';

type MyProps = {
  user: User | null,
  handleChange: (type: string) => void,
  QRcodes: any
}

const {REACT_APP_API_URL} = process.env;

export function QrCodesInfoMigrates(props: MyProps) {
  const {t} = useTranslation(['home']);
  const [types, setTypes] = useState<any>([]);
  const [selectedType, setSelectedType] = useState<string>("");
  const [selectedName, setSelectedName] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);
  const [canImport, setCanImport] = useState<boolean>(true);
  const [canUnarchived, setCanUnarchived] = useState<boolean>(true);
  const [qrCodeMsgCustom, setQrCodeMsgCustom] = useState<number>(0);
  const [step, setStep] = useState<number>(1);
  const [stateCampaignImport, setStateCampaignImport] = useState<number>(-1);
  const [stateTemplatesImport, setStateTemplatesImport] = useState<number>(-1);
  const [stateQrcodesImport, setStateQrcodesImport] = useState<number>(-1);
  const [stateRelinksImport, setStateRelinksImport] = useState<number>(-1);
  const [stateFilterImport, setStateFilterImport] = useState<number>(-1);

  //! LIFECYCLE

  useEffect(() =>  {
    setTypes([
      {name: "Vcard+"},
      {name: "Vcard-classic"},
      {name: "Packaging"}, 
      {name: "Dynamic-pro"},
      {name: "Dynamic"},
      {name: "Static"},
      {name: "Wifi"},
      {name: "Event"},
      {name: "Location"},
      {name: "Sms"},
      {name: "Email"}
    ]);
    if (props.user) {
      if (props.user.legacy_id !== null && props.user.legacy_id !== 0)
        setCanImport(false);
      else
        setCanImport(true);
    }
  }, []);

  useEffect(() =>  {
    if (selectedType === "") {
      setSelectedName(t("All_types"));
    } else {
      setSelectedName(selectedType);
    }
  }, [selectedType]);

  useEffect(() =>  {
    if (props.user)
      checkStatus();
  }, [props]);

  //! API CALL

  const checkStatus = () => {
    APIGet( REACT_APP_API_URL + "/admin/" + props.user?.uuid)
    .then((data: any) => {
      if (data.ok) {
        if (data.parsedBody.archived > 0)
          setCanUnarchived(false);
      } else {
        setCanUnarchived(true);
      }
    })
    .catch((error: any) => {
      console.log(error);
      setCanUnarchived(true);
    });
  }


  const doUnarchived = () => {
    APIPut( REACT_APP_API_URL + "/admin/" + props.user?.uuid, {})
    .then((data: any) => {
      if (data.ok) {
        setQrCodeMsgCustom(3);
        setCanUnarchived(true);
        setLoading(false);
      } else {
        setQrCodeMsgCustom(2)
        setLoading(false);
      }
    })
    .catch((error: any) => {
      console.log(error);
      setQrCodeMsgCustom(2)
      setLoading(false);
    });
  }

  const doMigrationCampaigns = () => {
    const url = REACT_APP_API_URL + "/migration/reimport/" + props.user?.uuid + "/campaigns";

    setStateCampaignImport(0);
    APIPost<any>(url, {})
    .then((data: any) => {
      if (data.status === 200) {
        setStateCampaignImport(1);
        doMigrationTemplates();
        setStep(2);
      } else if (data.status >= 400) {
        if (data.status === 408) { //TIME OUT CHECK
          setStateCampaignImport(1);
          doMigrationTemplates();
          setStep(2);
        } else
          setStateCampaignImport(2);
          setLoading(false);
      }
    }, (error: any) => {
      setStateCampaignImport(2);
      setLoading(false);
    })
  }

  const doMigrationTemplates = () => {
    const url = REACT_APP_API_URL + "/migration/reimport/" + props.user?.uuid + "/templates";

    setStateTemplatesImport(0);
    APIPost<any>(url, {})
    .then((data: any) => {
      if (data.ok) {
        setStateTemplatesImport(1);
        doMigrationQRcodes();
        setStep(3);
      } else if (data.status >= 400) {
        if (data.status === 408) { //TIME OUT CHECK
          setStateTemplatesImport(1);
          doMigrationQRcodes();
          setStep(3);
        } else
          setStateTemplatesImport(2);
          setLoading(false);
      }
    }, (error) => {
      setStateTemplatesImport(2);
      setLoading(false);
    })
  }

  const doMigrationQRcodes = () => {
    const url = REACT_APP_API_URL + "/migration/reimport/" + props.user?.uuid + "/qrcodes";

    setStateQrcodesImport(0);
    APIPost<any>(url, {})
    .then((data: any) => {
      if (data.ok) {
        setStateQrcodesImport(1);
        doMigrationRelinks();
        setStep(4);
      } else {
        if (data.status === 408) { //TIME OUT CHECK
          setStateQrcodesImport(1);
          doMigrationRelinks();
          setStep(4);
        } else {
          setStateQrcodesImport(2);
          setLoading(false);
          if ("user not found" === data.parsedBody) {
            setQrCodeMsgCustom(1);
          }
        }
      }
    }, (error: any) => {
      if ("user not found" === error.message)
        setQrCodeMsgCustom(1);
      setStateQrcodesImport(2);
      setLoading(false);
    })
  }

  const doMigrationRelinks = () => {
    const url = REACT_APP_API_URL + "/migration/reimport/" + props.user?.uuid + "/campaigns/relink";

    setStateRelinksImport(0);
    APIPost<any>(url, {})
    .then((data: any) => {
      if (data.ok) {
        setStateRelinksImport(1);
        doMigrationFilters();
        setStep(5);
      } else {
        if (data.status === 408) { //TIME OUT CHECK
          setStateRelinksImport(1);
          doMigrationFilters();
          setStep(5);
        } else {
          setStateRelinksImport(2);
          setLoading(false);
        }
      }
    }, (error: any) => {
      setStateRelinksImport(2);
      setLoading(false);
    })
  }

  const doMigrationFilters = () => {
    const url = REACT_APP_API_URL + "/migration/reimport/" + props.user?.uuid + "/filters";

    setStateFilterImport(0);
    APIPost<any>(url, {})
    .then((data: any) => {
      if (data.ok) {
        setStateFilterImport(1);
        setLoading(false);
        setCanImport(true);
      } else {
        if (data.status === 408 || data.status === 500) { //TIME OUT CHECK + internal server error check
          setStateFilterImport(1);
          setLoading(false);
          setCanImport(true);
        } else 
          setStateFilterImport(2);
          setLoading(false);
        }
    }, (error: any) => {
      // If the step "filters" fails with a "500, internal server error", it is considered a success
      if ("Internal Server Error" === error.message) {
        setStateFilterImport(1);
        setLoading(false);
        setCanImport(true);
      } else {
        setStateFilterImport(2);
        setLoading(false);
      }
    })
  }

  //! METHODS

  const handleReimport = () => {
    setQrCodeMsgCustom(0);
    setImporting(true);
    setLoading(true);
    doMigrationCampaigns();
  }

  const handleUnarchived = () => {
    setQrCodeMsgCustom(0);
    setLoading(true);
    doUnarchived();
  }

  const onTypeFilterChange = (event: SelectChangeEvent) => {
    if (event.target.value as string === "Vcard+") {
      setSelectedType("Vcard");
      props.handleChange("Vcard");
    } else {
      setSelectedType(event.target.value as string);
      props.handleChange(event.target.value as string);
    }
  }

  const typeBodyTemplate = (rowData: any) => {
    switch (rowData.name) {
      case "Vcard+":
        return <ColorChip value="vcard+" color="blue"/>;
      case "Vcard-classic":
        return <ColorChip value="vcard-classic" color="green"/>;
      case "Packaging":
        return <ColorChip value="packaging" color="orange"/>;
      case "Dynamic-pro":
          return <ColorChip value="dyn-pro" color="purple"/>;
      case "Dynamic":
          return <ColorChip value="dynamic" color="green"/>;
      case "Static":
          return <ColorChip value="static"/>;
      case "Wifi":
          return <ColorChip value="wifi"/>;
      case "Event":
          return <ColorChip value="event" color="red"/>;
      case "Location":
        return <ColorChip value="location" color="orange"/>;
      case "Sms":
        return <ColorChip value="SMS" color="yellow"/>;
      case "Email":
        return <ColorChip value="email" color="blue"/>;
      default:
        return;
    }
  }

  //! DISPLAY

  const displaySelect = () => {
    return (
      <Select displayEmpty
        value={selectedType}
        onChange={onTypeFilterChange}
        sx={{mb: 2, height: "100%"}}
      >
        <MenuItem value=''>
          <em>{t("All_types")}</em>
        </MenuItem>
          {types.map((type: any, index: number) => {
            return (
              <MenuItem key={index} value={type.name}>
                {typeBodyTemplate(type)}
              </MenuItem>
            );
          })}
      </Select>
    );
  }

  const displayCard = () => {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item md={2}>
            {
              props.QRcodes ?
              <UnitagPapernumber name={selectedName} value={props.QRcodes.total_records} justify="flex-start"/>
                :
              null
            }
          </Grid>
          <Grid item md={8}>
            {displaySelect()}
          </Grid>
          <Grid item container md={2} spacing={1} direction={"column"}>
            <Grid item>
              {
                loading ?
                <UnitagContainedButton icon={<CircularProgress color="secondary" size={21}/>} fullWidth disabled={canImport} />
                  :
                <UnitagContainedButton text={t("reimport")} func={handleReimport} fullWidth disabled={canImport} />
              }
            </Grid>
            <Grid item>
              {
                loading ?
                <UnitagContainedButton icon={<CircularProgress color="secondary" size={21}/>} fullWidth disabled={canUnarchived} />
                  :
                <UnitagContainedButton text={t("unarchived")} func={handleUnarchived} fullWidth disabled={canUnarchived} />
              }
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  const displayInfo = (info: string, color: string) => {
    return (
      <Card sx={{ display: 'flex', justifyContent: 'center', p: 1, mt: 2 }}>
        <Typography color={color}>{info}</Typography>
      </Card> 
    );
  }

  const diplayCustomErrors = () => {
    if (qrCodeMsgCustom === 1)
      return displayInfo(t("multi_account"), "#BD473C");
    else if (qrCodeMsgCustom === 2)
      return displayInfo(t("unarchived_error"), "#BD473C");
    else if (qrCodeMsgCustom === 3)
      return displayInfo(t("unarchived_success"), "#19B846");
    else
      return null;
  }

  const displayState = (StateName: string, state: number) => {
    if (state === 0)
      return <Typography  color="orange">{StateName} {t("reimporting")}</Typography>;
    else if (state === 1)
      return <Typography sx={{ color:"#19B846", justifyContent: "left" }} >{StateName} {t("reimport_success")}</Typography>;
    else if (state === 2)
      return <Typography sx={{ color:"#BD473C", justifyContent: "left" }} >{StateName} {t("reimport_error")}</Typography>;
    else
      return null;
  }

  const displayReImporting = () => {
    return (
      <Card sx={{ display: 'flex', padding: 1, marginTop: 5, justifyContent: "left" }}>
        <CardContent>
          <Typography sx={{ fontWeight: 600, marginBottom: 3 }} >{t("Migration_step")} {"<"}{step}/5{">"}</Typography>
          {displayState("Campaigns", stateCampaignImport)}<div/>
          {displayState("Templates", stateTemplatesImport)}<div/>
          {displayState("QRcodes", stateQrcodesImport)}<div/>
          {displayState("Relinks", stateRelinksImport)}<div/>
          {displayState("Filters", stateFilterImport)}
        </CardContent>
      </Card>
    )
  }

  const display = () => {
    if (props.user !== null) {
      return (
        <React.Fragment>
          {displayCard()}
          {importing ? displayReImporting() : null}
          {step === 5 && stateFilterImport === 1 ? displayInfo(t("all_reimport_done"), "#19B846") : null}
          {qrCodeMsgCustom ? diplayCustomErrors() : null}
        </React.Fragment>
      );
    } else
      return null;
  }

  return display();
}
