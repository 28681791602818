import React from 'react';
import { Paper, Typography } from '@mui/material';

type MyProps = {
  name: string;
  value: number;
  fullWidth?: boolean;
  justify?: string;
}

export default function UnitagPapernumber(props: MyProps) {

  //! DISPLAY

  const display = () => {
    return (
      <Paper variant="outlined" sx={{p: 3, justifyContent: props.justify, maxWidth: 150}}>
        <Typography variant="h6" component="p">{props.name}</Typography>
        <Typography variant="h4" component="p">{props.value}</Typography>
      </Paper>
      );
  }

  return display();
}