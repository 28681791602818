// BASE
import React from 'react';
import {Tooltip} from '@mui/material';
import {useTranslation} from "react-i18next";
import {Auth} from 'aws-amplify';
import {useNavigate} from 'react-router-dom';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';

export default function SignOut() {
    const {t} = useTranslation(['login']);
    const navigate = useNavigate();

    //! METHODS

    const signout = () => {
      Auth.signOut()
      .then((sess) => {
        console.log(sess);
        navigate("/login")
      })
      .catch((err) => {
        console.log(err);
      })
    }

    return (
      <Tooltip title={`${t("signout")}`} arrow onClick={signout}>
        <DoDisturbOnIcon/>
      </Tooltip>
    );
}
