import React, {Suspense, useEffect} from 'react';

import {useNavigate} from 'react-router-dom';


import {Amplify, Auth} from 'aws-amplify';
import {Backdrop, CircularProgress} from "@mui/material";
import Login from "./components/login";


const {
    REACT_APP_COGNITO_POOL_ID,
    REACT_APP_COGNITO_APP_ID,
} = process.env;

Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: REACT_APP_COGNITO_POOL_ID,
        userPoolWebClientId: REACT_APP_COGNITO_APP_ID,
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    }
});


export default function LoginComponent() {
    const navigate = useNavigate();

    useEffect(() => {
        Auth.currentSession().then((sess) => {
            sess.getIdToken();
            console.log(sess);
            navigate('/');
            // setActiveModule(<Dashboard/>);
        }, (error) => {
            console.log(error);
        });
    }, []);

    const loader = () => {
        return (
            <Backdrop style={{color: 'white'}} open={true}>
                <CircularProgress color='inherit'/>
            </Backdrop>
        );
    }

    return (
        <Suspense fallback={loader}>
            <Login/>
        </Suspense>
    );
}
