import i18n from 'i18next'
// import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

// TRANSLATION FILES (FR)
import dashboardFR from "./locales/fr/dashboardFR.json"
import loginFR from "./locales/fr/loginFR.json"

// TRANSLATION FILES (EN)
import dashboardEN from "./locales/en/dashboardEN.json"
import loginEN from "./locales/en/loginEN.json"


// Namespaces linking with imported JSON files
const resources = {
    en: {
        home: dashboardEN,
        login: loginEN,
    },
    fr: {
        home: dashboardFR,
        login: loginFR,
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: {
            'en-EN': ['en'],
            'fr-FR': ['en']
        },
        debug: false,
        interpolation: {
            // may not be useful with React as it escapes by default
            escapeValue: false,
        },
        resources,
        defaultNS: 'common',
        fallbackNS: 'common',
    });

export default i18n;
