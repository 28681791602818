import { useEffect, useState } from "react";
import { Grid, Paper, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from 'react-i18next';
import ColorChip from "../../../../../utils/colorChip";

type arrayStruct = {
  name: string,
  uuid: string,
  color: string
}

type MyProps = {
  qrcode: any
}

export function QrCodeOrganisation(props: MyProps) {
  const {t} = useTranslation(['home']);
  const [organisation, setOrganisation] = useState<Array<arrayStruct>>([]);

  //! LIFECYCLE HOOKS

  useEffect(() => {
    if (props.qrcode)
      getOrganisations();
  }, [props.qrcode]);

  //! METHODS

  const getOrganisations = () => {
    let organisations: Array<arrayStruct> = []

    if (props.qrcode.organisation && props.qrcode.organisation.name !== "") {
      if (props.qrcode.organisation.ChildOrgs)
        props.qrcode.organisation.ChildOrgs.map((org: any) => {
          organisations.push({
            name: org.name,
            uuid: org.organisation_id, 
            color: ""
          });
        })
      if (props.qrcode.organisation.ParentOrg)
        organisations.push({
          name: props.qrcode.organisation.ParentOrg.name,
          uuid: props.qrcode.organisation.ParentOrg.organisation_id,
          color: ""
        });
      organisations.push({
        name: props.qrcode.organisation.name,
        uuid: props.qrcode.organisation.organisation_id, 
        color: ""
      });
    }
    setOrganisation(organisations);
  }

  //! DISPLAY

  const display = () => {
    return (
      <Grid container pb={2}>
        <Paper variant="outlined" elevation={0} sx={{width: "100%", borderTop: "5px solid rgb(175,201,40)"}}>
          <ListItem dense={true} style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              backgroundColor: "#f6f8fa",
            }}
          >
            <ListItemText sx={{fontWeight: 500, fontSize: "1.2rem"}} primary={t("Organisation")}
              primaryTypographyProps={{
                variant: "h6",
                style: {lineHeight: "1.2"}
              }}
            />
          </ListItem>
          <Grid item container alignItems={"center"} minHeight={"100px"} pl={2}>
            {
              organisation.map((info: arrayStruct) => {
                return (
                  <Grid item md={2} textAlign={"center"}>
                    <ColorChip value={info.name} color={info.color}/>
                  </Grid>
                );
              })
            }
          </Grid>
        </Paper>
      </Grid>
    );
  }

  return display();

}