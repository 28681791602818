import {
    Button, CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import {APIPost, APIPut} from "../../../FetchBuilder";
import {QRCode} from "../../../ifaces";


const {REACT_APP_API_URL} = process.env;

export function RemoveLimitOnQRcode(props: any) {

    const [shortUrl, setShortUrl] = useState<string>('');
    const [err, setErr] = useState<string>("");
    const [respType, setRespType] = useState<string>('ok');
    const [qr, setQr] = useState<QRCode | null>(null);
    const [loading, setLoading] = useState(false);

    const handleChangeShortUrl = (event: any) => {
        setShortUrl(event.currentTarget.value);
    }

    const handleRemoveLimit = () => {
        setLoading(true);
        APIPut<string>(REACT_APP_API_URL + "/admin/lift-limit", {
            short_url: shortUrl
        }).then((data) => {
            if (data.status != 200) {
                setRespType("notOk");
                setLoading(false);
                return;
            }
            setErr(data.parsedBody as string);
            setLoading(false);
        }, (error) => {
            console.log(error);
        });
    }

    const handleSearchQR = () => {
        setLoading(true);
        APIPost<QRCode | string>(REACT_APP_API_URL + "/admin/qr-details", {
            short_url: shortUrl
        }).then((data) => {
            if (data.status != 200 && data.parsedBody != null) {
                setRespType("notOk");
                setErr(data.parsedBody as string);
                setLoading(false);
                return
            }
            if (data.parsedBody !== undefined) {
                setQr(data.parsedBody as QRCode);
            }
            setLoading(false);
        }, (error) => {
            console.log(error);
        });
    }

    const handleClear = () => {
        setErr('');
    }

    const display = () => {
        return (
            <Grid container spacing={1} alignItems='center' justifyContent='center' sx={{marginTop: '5px', mr: 2}}>
                {
                    err !== '' ?
                        <>
                            <Grid item xs={10} sx={{textAlign: 'center', padding: '10px', border: '1px solid black'}}>
                                Réponse de l'opération : <span
                                style={respType === "ok" ? {color: 'forestgreen'} : {color: 'mediumvioletred'}}>{err}</span>
                            </Grid>
                            <Grid item xs={2} sx={{paddingBottom: '10px'}}>
                                <Button
                                    disabled={shortUrl === ''}
                                    variant={'contained'}
                                    onClick={handleClear}
                                >
                                    {'Clear'}
                                </Button>
                            </Grid>
                        </>
                        : null
                }
                <Grid item xs={4}>
                    <TextField
                        label={'URL courte'}
                        value={shortUrl}
                        margin={'dense'}
                        variant={'outlined'}
                        type={'text'}
                        onChange={handleChangeShortUrl}
                        style={{width: '100%'}}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant={'contained'}
                        onClick={handleSearchQR}
                    >
                        {'Chercher'}
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        disabled={shortUrl === ''}
                        variant={'contained'}
                        onClick={handleRemoveLimit}
                    >
                        {'Enlever limite'}
                    </Button>
                </Grid>
                {loading
                    ? <CircularProgress size={21} sx={{m: 1}}/>
                    : qr !== null ? <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: 650}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">status</TableCell>
                                            <TableCell align="left">email</TableCell>
                                            <TableCell align="left">Créé le :</TableCell>
                                            <TableCell align="left">Mis à jour le :</TableCell>
                                            <TableCell align="left">Supprimé le :</TableCell>
                                            <TableCell align="left">type</TableCell>
                                            <TableCell align="left">résolution</TableCell>
                                            <TableCell align="left">short_url</TableCell>
                                            <TableCell align="left">URL FINALE</TableCell>
                                            <TableCell align="left">nom</TableCell>
                                            <TableCell align="left">raison de désactivation</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            key={qr.uuid}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {qr.qr_status}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {qr.owner_email}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {qr.CreatedAt}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {qr.UpdatedAt}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {qr.DeletedAt}
                                            </TableCell>
                                            <TableCell align="left">{qr.type}</TableCell>
                                            <TableCell align="left">{qr.resolution_type}</TableCell>
                                            <TableCell align="left">{qr.short_url}</TableCell>
                                            <TableCell align="left">{qr.full_url}</TableCell>
                                            <TableCell align="left">{qr.label}</TableCell>
                                            <TableCell align="left">{
                                                qr.deactivation_reason === 'unsubscribed' ?
                                                    'désabonné/archivé'
                                                    : qr.deactivation_reason
                                            }</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        : null
                }

            </Grid>
        )
    }

    return display();
}
