import React from 'react';
import { Grid } from '@mui/material';
import { User, UrlStruct } from "../../../../../ifaces";
import { QrCodeBasicInfo } from './QrCodeBasicInfo';
import { QrCodePreview } from './QrCodePreview';
import { QrCodeCampaign } from './QrCodeCampaign';
import { QrCodeOrganisation } from './QrCodeOrganistion';
import { QrCodeFilters } from './QrcodeFilters';
import CloseIcon from '@mui/icons-material/Close';
import ColorChip from '../../../../../utils/colorChip';

type MyProps = {
  qrcode: any,
  user: User | null,
  url: UrlStruct,
  handleEdit: (qrcode: any, row: number, page: number, label: string) => void
}

export function EditQrCode(props: MyProps) {

  //! DISPLAY

  const displayColorChip = (info: any) => {
    if (info.DeletedAt && info.deactivation_reason !== "")
      return <ColorChip value="archived" color="orange"/>;
    else if (info.DeletedAt && info.deactivation_reason === "")
      return <ColorChip value="deleted" color="purple"/>;
    else
      return <ColorChip value="active" color="green"/>;
  }

  const displayHeader = () => {
    return (
      <Grid item container sx={{mt: 2}}>
        <Grid item md={6} sx={{textAlign: "start"}}>
          {displayColorChip(props.qrcode)}
        </Grid>
        <Grid item md={6} sx={{textAlign: "end"}}>
          <CloseIcon sx={{ minWidth: "50px", height: "auto", cursor: "pointer" }} onClick={(e: any) => {props.handleEdit(null, props.url.row, props.url.page, props.url.label)}}/>
        </Grid>
      </Grid>
    );
  }

  const displayMainCard = () => {
    return (
      <Grid item container spacing={3} my={0} mb={2}>
        <Grid item md={8}>
          <QrCodeBasicInfo qrcode={props.qrcode}/>
        </Grid>
        <Grid item md={4} sx={{textAlign: "end"}}>
          <QrCodePreview qrcode={props.qrcode} user={props.user}/>
        </Grid>
      </Grid>
    );
  }

  const display = () => {
    if (props.qrcode)
      return (
        <React.Fragment>
          {displayHeader()}
          {displayMainCard()}
          <QrCodeCampaign qrcode={props.qrcode}/>
          <QrCodeOrganisation qrcode={props.qrcode}/>
          <QrCodeFilters qrcode={props.qrcode}/>
        </React.Fragment>
      );
    return null;
  }

  return display();
}