import { useEffect, useState } from "react";
import { Grid, Paper, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from 'react-i18next';
import ColorChip from "../../../../../utils/colorChip";

type arrayStruct = {
  name: string,
  uuid: string,
  color: string
}

type MyProps = {
  qrcode: any
}

export function QrCodeCampaign(props: MyProps) {
  const {t} = useTranslation(['home']);
  const [campaign, setCampaign] = useState<Array<arrayStruct>>([]);

  //! LIFECYCLE HOOKS

  useEffect(() => {
    if (props.qrcode)
      getCampaigns();
  }, [props.qrcode]);


  //! METHODS

  const getCampaigns = () => {
    let campaigns: Array<arrayStruct> = []

    if (props.qrcode.Campaign && props.qrcode.Campaign.name !== "") {
      campaigns.push({
        name: props.qrcode.Campaign.name,
        uuid: props.qrcode.Campaign.uuid, 
        color: props.qrcode.Campaign.color
      });
      if (props.qrcode.Campaign.ParentCampaign) {
        campaigns.push({
          name: props.qrcode.Campaign.ParentCampaign.name,
          uuid: props.qrcode.Campaign.ParentCampaign.uuid,
          color: props.qrcode.Campaign.ParentCampaign.color
        });
      }
    }
    setCampaign(campaigns);
  }

  //! DISPLAY

  const display = () => {
    return (
      <Grid container pb={2}>
        <Paper variant="outlined" elevation={0} sx={{width: "100%", borderTop: "5px solid rgb(175,201,40)"}}>
          <ListItem dense={true} style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              backgroundColor: "#f6f8fa",
            }}
          >
            <ListItemText sx={{fontWeight: 500, fontSize: "1.2rem"}} primary={t("Campaigns")}
              primaryTypographyProps={{
                variant: "h6",
                style: {lineHeight: "1.2"}
              }}
            />
          </ListItem>
          <Grid item container alignItems={"center"} minHeight={"100px"} pl={2}>
            {
              campaign.map((info: arrayStruct, index: number) => {
                return (
                  <Grid item md={2} key={index}>
                    <ColorChip value={info.name} color={info.color}/>
                  </Grid>
                );
              })
            }
          </Grid>
        </Paper>
      </Grid>
    );
  }

  return display();

}