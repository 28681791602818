import React, {useEffect, useState} from "react";
import {Alert, AlertColor, Snackbar} from "@mui/material";

type MyProps = {
  open: boolean
  message: string
  severity: AlertColor
  close: () => void
  duration?: number
}

export function SnackInformation(props: MyProps) {
  const [duration, setDuration] = useState<number>(6000);

  useEffect(() => {
    if (!props.duration)
      setDuration(6000);
    else
      setDuration(props.duration);
  }, []);

  const display = () => {
    return (
      <React.Fragment>
        <Snackbar open={props.open} autoHideDuration={duration} onClose={props.close}>
          <Alert onClose={props.close} severity={props.severity} sx={{ width: '100%' }}>
            {props.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }

  return display();
}