import React from 'react';
import {IconButton, Menu, MenuItem, Tooltip} from '@mui/material';

import TranslateIcon from '@mui/icons-material/Translate';

// TRANSLATION
import i18next from 'i18next';
import {useTranslation} from "react-i18next";


export default function LanguageMenu() {
    const {t} = useTranslation(['navigation', 'dashboard']);

    // variables du menu
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const ITEM_HEIGHT = 48;

    const toggleLangEn = () => {
        i18next.changeLanguage('en-EN', (err, t) => {
            if (err) return console.log('error : ', err);
            t('key');
        });
        handleClose();
    }

    const toggleLangFr = () => {
        i18next.changeLanguage('fr-FR', (err, t) => {
            if (err) return console.log('error : ', err);
            t('key');
        });
        handleClose();
    }

    return (
        <div>
            <Tooltip title={`${t("Language")}`} arrow>
                <IconButton aria-label={`${t("Language")}`} aria-controls="menu-language" aria-haspopup="true"
                            onClick={handleMenu} color="inherit">
                    <TranslateIcon/>
                </IconButton>
            </Tooltip>

            <Menu id="menu-language"
                  anchorEl={anchorEl} anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                  transformOrigin={{vertical: "top", horizontal: "center"}}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  PaperProps={{style: {maxHeight: ITEM_HEIGHT * 3.5, width: 'auto'}}}>
                <MenuItem onClick={toggleLangEn}>English</MenuItem>
                <MenuItem onClick={toggleLangFr}>Français</MenuItem>
            </Menu>
        </div>
    );
}
