import { useEffect, useState } from "react";
import { Grid, Paper, ListItem, ListItemText, Chip, Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { Filter, GetFilterResponse, Statement } from "../../../../../ifaces";
import { useTranslation } from 'react-i18next';
import { APIGet } from "../../../../../FetchBuilder";
import LanguageCodeToName from "../../../../../utils/LanguageCodeToName";
import CountryCodeToName from "../../../../../utils/CountryCodeToName";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ColorChip from "../../../../../utils/colorChip";
import React from "react";

type MyProps = {
  qrcode: any
}

const {REACT_APP_API_URL} = process.env;

export function QrCodeFilters(props: MyProps) {
  const {t} = useTranslation(['home']);
  const [filters, setFilters] = useState<Array<Filter>>([]);

  //! LIFECYCLE HOOKS

  useEffect(() => {
    console.log(props.qrcode);
    if (props.qrcode)
      refreshFilters();
  }, [props]);

  //! API CALLS

  const refreshFilters = () => {
    // Load filters
    APIGet<GetFilterResponse>(REACT_APP_API_URL + "/qrcode/" + props.qrcode.uuid + "/filters")
    .then((data: any) => {
      if (!data.ok) {
        //    TODO(vincent): snackbar
        return;
      }
      if (data.parsedBody !== undefined) {
        if (data.parsedBody.filters)
          setFilters(data.parsedBody.filters);
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  //! DISPLAY

  const renderStatementWithoutDelete = (statement: Statement) => {
    switch (statement.type) {
      case "device":
        return (
          <ColorChip color={"orange"}
            value={"Device: " + statement.value}/>
        );
      case "language":
        return (
          <ColorChip color={"blue"}
            value={"Language: " + LanguageCodeToName(statement.value)}/>
        );
      case "geolocation":
        return (
          <Chip
            key={statement.index}
            color="primary"
            style={{
              backgroundColor: "#AFC928",
              color: "white",
              marginLeft: '5px',
            }}
            icon={<LocationOnIcon/>}
            label={statement.value}
          />
        );
      case "location_country":
        return (
          <ColorChip color={"green"}
            value={"Location: " + CountryCodeToName(statement.value)}/>
        );
      default:
        return null;
    }
  }

  const renderStatementsBody = (rowData: Filter) => {
    return (
      rowData.statements.map((statement: Statement, index: number) => {
        return (
          <React.Fragment key={index}>
            {renderStatementWithoutDelete(statement)}
          </React.Fragment>
        )
      })
    )
  }

  const display = () => {
    return (
      <Grid container pb={2}>
        <Paper variant="outlined" elevation={0} sx={{width: "100%", borderTop: "5px solid rgb(175,201,40)"}}>
          <ListItem dense={true} style={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            backgroundColor: "#f6f8fa",
          }}>
            <ListItemText sx={{fontWeight: 500, fontSize: "1.2rem"}} primary={t("Filters")}
              primaryTypographyProps={{
                variant: "h6",
                style: {lineHeight: "1.2"}
              }}
            secondary={t("Filters_subtitle")}/>
          </ListItem>
        </Paper>
        <TableContainer component={Paper} sx={{minHeight: "130px"}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{backgroundColor: "#f6f8fa"}}>
              <TableRow>
                <TableCell>{t("conditions")}</TableCell>
                <TableCell align="left">{t("final_url")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filters.map((filter: Filter, index: number)=> {
                return (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {renderStatementsBody(filter)}
                    </TableCell>
                    <TableCell align="left">{filter.final_url}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }

  return display();  
}