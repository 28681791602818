// BASE
import React from 'react';
// Material UI
import {AppBar, Button, Theme, Toolbar} from '@mui/material';

import {createStyles, makeStyles} from '@mui/styles';

//SVGs
import {ReactComponent as UnitagLogo} from "../../img/logo-unitag.svg";
import LanguageMenu from "./components/languageMenu";
import SignOut from './components/signOut';


const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        root: {flexGrow: 1},

        /* NAVBAR STYLE */
        menuButton: {marginLeft: '16px'},
        unitagLogo: {height: '35px', width: 'auto', fill: '#ffffff'},

        reducedListPadding: {padding: '4px 0px'},
        drawerWidth: {minWidth: "280px"},

        iconNormalize: {height: 24, width: 24, fill: '#626262'},
        /* MENU STYLE */
        grow: {
            flexGrow: 1,
        },
        menuPop: {
            minWidth: '180px',
            // zIndex: theme.zIndex.drawer + 2,
            fontWeight: 400,
            fontSize: 14,
            color: '#676767',
        },
        menuOrgText: {
            fontWeight: 300,
            fontSize: 14,
            color: '#676767',
        },
    })
);

export default function Navbar() {
    const classes: any = useStyles();

    return (
        <React.Fragment>
            <div>
                <AppBar position="static" color="primary" style={{zIndex: 1}}>
                    <Toolbar>
                        {/* Left section */}
                        <Button aria-label="back to main dashboard">
                            <UnitagLogo className={classes.unitagLogo}/>
                        </Button>

                        <LanguageMenu/>
                        <SignOut/>
                    </Toolbar>
                </AppBar>
            </div>
        </React.Fragment>
    );
}
