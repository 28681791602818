import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Grid, TextField, CircularProgress, Typography } from '@mui/material';
import {useTranslation} from 'react-i18next';
import {User, States} from "../../../../ifaces";
import EditIcon from '@mui/icons-material/Edit';
import CachedIcon from '@mui/icons-material/Cached';
import ColorChip from '../../../../utils/colorChip';
import UnitagContainedButton from '../../../../utils/unitagContainedButton';

type MyProps = {
  user: User | null,
  QRcodes: any,
  previews: any,
  maxRows: number,
  states: States,
  pageNumber: number,
  filterLabel: string,
  handleEdit: (qrcode: any) => void,
  handlechange: (maxRows: number, pageNumber: number, filterLabel: string) => void,
}


export function QrCodeList(props: MyProps) {
  const {t} = useTranslation(['home']);
  const [label, setLabel] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selects, setselects] = useState<Array<string>>([]);
  const [selected, setselected] = useState<Array<boolean>>([]);

  //! LIFECYCLE

  useEffect(() => {
    let selectedBool: Array<boolean> = [];

    if (props.QRcodes && props.QRcodes.records) {
      for (let i = 0; i < props.QRcodes.records.length; i++) {
        selectedBool[i] = false;
      }
    }
    setselected(selectedBool);
  }, []);

  useEffect(() => {
    if (props.QRcodes && props.QRcodes.records)
      refreshSelection();
    setLoading(false);
  }, [props.QRcodes]);

  useEffect(() => {
    if (props.QRcodes && props.QRcodes.records)
      refreshSelection();
  }, [selects]);


  //! METHODS

  const refreshSelection= () => {
    let selectedBool: Array<boolean> = [];
    let uuids: string[] = [];

    props.QRcodes.records.map((info: any) => {
      uuids.push(info.uuid);
      selectedBool.push(false);
    })
    selects.map((uuid: string) => {
      const index = uuids.indexOf(uuid);
      if (index > -1)
        selectedBool[index] = true;
    })
    setselected(selectedBool);
  }

  const handlePrevious = () => {
    if (props.QRcodes && props.QRcodes.records)
      return props.pageNumber === 1;
    return false;
    }

  const handleNext = () => {
    if (props.QRcodes && props.QRcodes.records)
      return props.maxRows > props.QRcodes.records.length || props.QRcodes.total_records === props.maxRows;
    return false;
  }

  const handleChangeLabel = (event: any) => {
    setLabel(event.currentTarget.value);
  }

  const handleSearchClick = () => {
    props.handlechange(props.maxRows, 1, label)
  }

  const handleEdit = (info: any) => {
    props.handleEdit(info);
  }

  const handleSelect = (info: any) => {
    let select: string[] = [...selects];
    const i = findInSelects(info.uuid);

    if (i !== -1) {
      select.splice(i, 1);
    } else {
      select.push(info.uuid);
    }
    setselects(select);
  }

  const findInSelects = (value: string) => {
    for (let i = 0; selects.length > i ;i++)
      if (value === selects[i])
        return (i);
    return (-1);
  }


  //! DISPLAY

  const displayLabelSearch = () => {
    return (
      <Grid item container spacing={0} marginTop={3} alignItems="center">
        <Grid item md={4} sx={{textAlign: "start", px: 1}}>
          <UnitagContainedButton 
              icon={<CachedIcon />}
              func={
                (e: any) => {
                  setLabel("");
                  props.handlechange(8, 1, "")
                }
              }
          />
        </Grid>
        <Grid item md={4} sx={{textAlign: "center", pr: 1}}>
          <TextField
            fullWidth
            label={t('Label')}
            value={label}
            margin={'dense'}
            variant={'outlined'}
            type={'text'}
            onChange={handleChangeLabel}
          />
        </Grid>
        <Grid item md={4} sx={{textAlign: "start"}}>
          <Button
            variant={'contained'}
            onClick={handleSearchClick}
          >
            {t('Search')}
          </Button>
        </Grid>
      </Grid>
    );
  }

  const displayButtons = () => {
    if (loading)
      return (
        <Grid item container md={12} marginTop={3} sx={{textAlign: "center"}}>
          <Grid item md={6}>
            <UnitagContainedButton 
              icon={<CircularProgress color="secondary" size={21}/>}
              disabled 
            />
          </Grid>
          <Grid item md={6}>
            <UnitagContainedButton 
              icon={<CircularProgress color="secondary" size={21}/>}
              disabled
            />
          </Grid>
        </Grid>
      );
    return (
      <Grid item container md={12} marginTop={3} sx={{textAlign: "center"}}>
        <Grid item md={6}>
          <UnitagContainedButton 
            text={t('previous_page')} 
            func={() => {
              setLoading(true);
              props.handlechange(props.maxRows, props.pageNumber - 1, props.filterLabel);
            }}
            disabled={handlePrevious()} 
          />
        </Grid>
        <Grid item md={6}>
          <UnitagContainedButton 
            text={t('next_page')} 
            func={() => {
              setLoading(true);
              props.handlechange(props.maxRows, props.pageNumber + 1, props.filterLabel);
            }}
            disabled={handleNext()}
          />
        </Grid>
      </Grid>
    );
  }

  const displaySideQrcode = (info: any, index: number) => {
    return (
      <Grid item md={2} sx={{textAlign: "center"}}>
        { selected.length ? <Checkbox checked={selected[index]} onChange={(e: any) => {handleSelect(info)}} /> : null}
        <EditIcon onClick={() => {handleEdit(info)}} sx={{color: "black", cursor: "pointer"}}/>
      </Grid>
    );
  }

  const displayColorChip = (info: any) => {
    if (info.DeletedAt && info.deactivation_reason !== "")
      return <ColorChip value="archived" color="orange" sx={{position: "absolute", ml: 15, mt: 2}}/>;
    else if (info.DeletedAt && info.deactivation_reason === "")
      return <ColorChip value="deleted" color="purple" sx={{position: "absolute", ml: 15, mt: 2}}/>;
    else
      return <ColorChip value="active" color="green" sx={{position: "absolute", ml: 15, mt: 2}}/>;
  }

  const displayQrCodeBody = (info: any) => {
    if (props.user)
      return (
        <Grid item md={10} sx={{textAlign: "center"}}>
          {displayColorChip(info)}
          <img 
            width={'200px'} 
            height={'auto'} 
            src={props.previews[info.uuid]} 
            alt="qrcode-preview"
          />
        </Grid>
      );
    return null
  }

  const displayQrcodes = () => {
    if (props.user && props.QRcodes) {
      return (
        <Grid container sx={{mt: 2}}>
          {props.QRcodes ? props.QRcodes.records.map((info: any, index: number) => {
            return (
              <Card sx={{m: 1, textAlign: "center", width: 250, p: 1}} key={info.uuid}>
                <Grid item container md={12}>
                  {displaySideQrcode(info, index)}
                  {displayQrCodeBody(info)}
                </Grid>
                
                <Typography sx={{mb: 1, backgroundColor: "#b3e5fc", p: 1}}>{info.label}</Typography>
              </Card>
            );
          }) : null}
        </Grid>
      );
    } else
      return null;
  }

  const display = () => {
    return (
      <React.Fragment>
        {displayLabelSearch()}
        {displayQrcodes()}
        {displayButtons()}
      </React.Fragment>
    )
  }

  return display();
}
