import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Container, Grid, Typography} from "@mui/material";
import {SearchUserByEmail} from "./components/searchUser";
import {UserInformation} from "./components/userInformation";
import {Auth} from 'aws-amplify';
import {useNavigate} from 'react-router-dom';
import {APIPost} from "../../FetchBuilder";
import {User, UrlStruct, States} from "../../ifaces";
import {EditQrCode} from './components/QrCodesDisplay/QrCodeEdit/editQrCode';
import {RemoveLimitOnQRcode} from "./components/removeLimitOnQRcode";

const {REACT_APP_API_URL} = process.env;

export function Dashboard() {
    const {t} = useTranslation(['home']);
    const navigate = useNavigate();

    const [user, setUser] = useState<User | null>(null);
    const [err, setErr] = useState<string>("");
    // EDIT
    const [editQrcode, setEditQrcode] = useState<any | null>(null);
    const [url, setUrl] = useState<UrlStruct>({row: 0, page: 0, label: ""});
    const [states, setStates] = useState<Array<States>>([
        {editing: false, creating: false, list: false},
        {editing: false, creating: false, list: false},
        {editing: false, creating: false, list: false},
        {editing: false, creating: false, list: false}
    ]);

    //! LIFECYCLE HOOK

    useEffect(() => {
        Auth.currentSession().then((sess: any) => {
            sess.getIdToken();
            navigate('/');
        }, (error: any) => {
            navigate('/login');
        });
    }, []);

    //! METHODS

    const handleEditQrcode = (qrcode: any, row: number, page: number, label: string) => {
        setEditQrcode(qrcode);
        setUrl({
            row: row,
            page: page,
            label: label
        });
    }

    const handleStates = (state: States, index: number) => {
        switch (index) {
            case 0:
                setStates([
                    state,
                    {editing: false, creating: false, list: false},
                    {editing: false, creating: false, list: false},
                    {editing: false, creating: false, list: false}
                ])
                break;
            case 1:
                setStates([
                    {editing: false, creating: false, list: false},
                    state,
                    {editing: false, creating: false, list: false},
                    {editing: false, creating: false, list: false}
                ])
                break;
            case 2:
                setStates([
                    {editing: false, creating: false, list: false},
                    {editing: false, creating: false, list: false},
                    state,
                    {editing: false, creating: false, list: false}
                ])
                break;
            case 3:
                setStates([
                    {editing: false, creating: false, list: false},
                    {editing: false, creating: false, list: false},
                    {editing: false, creating: false, list: false},
                    state
                ])
                break;
            default:
                setStates([
                    {editing: false, creating: false, list: false},
                    {editing: false, creating: false, list: false},
                    {editing: false, creating: false, list: false},
                    {editing: false, creating: false, list: false}
                ])
                break;
        }
    }

    const fetchUserByEmail = (_email: string) => {
        setUser(null);
        if (_email !== "") {
            APIPost<User>(REACT_APP_API_URL + "/admin/user/email", {
                email: _email
            }).then((data) => {
                setErr("");
                if (!data.ok) {
                    const error = data as any;
                    switch (error.parsedBody) {
                        case "internal server error":
                            setUser(null);
                            setErr("internal_server_error_msg");
                            break;
                        case "user not found":
                            setUser(null);
                            setErr("user_not_found_msg");
                            break;
                    }
                    setUser(null);
                } else if (data.parsedBody !== undefined)
                    setUser(data.parsedBody);
            }, (error) => {
                setUser(null);
                console.log(error);
            });
        }
    }

    const [show, setShow] = useState(true);

    const changeShowState = () => {
        setShow(!show);
    }



    //! DISPLAY

    const displayBody = () => {
        if (editQrcode) {
            return <EditQrCode user={user} qrcode={editQrcode} handleEdit={handleEditQrcode} url={url}/>
        } else {
            return (
                <Grid item xs={12}>
                    <Grid sx={{span: '50px', border: '1px solid black', marginTop: '25px'}}>{show ?
                        <span onClick={() => changeShowState()} style={{paddingLeft: '25px'}}>cacher ↑</span> :
                        <span onClick={() => changeShowState()}
                              style={{paddingLeft: '25px'}}>voir qrcode section ↓</span>}
                        {
                            show ? <RemoveLimitOnQRcode/>
                                : null
                        }
                    </Grid>
                    <SearchUserByEmail
                        displayInfos={(_email: string) => fetchUserByEmail(_email)}
                    />

                    <Grid item md={12} sx={{textAlign: "center", mb: 2}}>
                        <Typography color="Red">{t(err)}</Typography>
                    </Grid>
                    {user !== null ?
                        <UserInformation user={user} handleEdit={handleEditQrcode} handleStates={handleStates} url={url}
                                         states={states}/>
                        :
                        null
                    }
                </Grid>
            );
        }
    }

    const display = () => {
        return (
            <Container maxWidth={false} style={{paddingBottom: '75px'}}>
                {displayBody()}
            </Container>
        );
    }

    return display();
}
