import React, {useEffect} from 'react';
import {DataGrid, GridRowsProp, GridColDef, GridEditRowsModel} from '@mui/x-data-grid';
import {AlertColor, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {APIPut, APIGet} from '../../../FetchBuilder';
import {SnackInformation} from '../../../utils/snackInformation';
import {CreditsResponse} from "../../../ifaces";
import UnitagContainedButton from '../../../utils/unitagContainedButton';

type MyProps = {
  uuid?: string
}

const {REACT_APP_API_URL} = process.env;

export function CreditsTable(props: MyProps) {
  const {t} = useTranslation(['home']);
  const [credits, setCredits] = React.useState<any>(null)
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  // SnackBar Props
  const [severity, setSevirity] = React.useState<AlertColor>("success");
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");

  useEffect(() => {
    if (props.uuid !== undefined)
      FetchCredits();
  }, [props]);

  useEffect(()=> {
    if (credits !== null) 
      setRows(addIdToCredits());
  }, [credits]);

  //! API

  const FetchCredits = () => {
    APIGet<CreditsResponse>(REACT_APP_API_URL + "/admin/billing/credits/" + props.uuid).then((data) => {
      if (data.parsedBody !== undefined)
        setCredits(data.parsedBody);
    }, (error) => {
      console.log(error);
    });
  }

  //! METHODS

  const addIdToCredits = () => {
    let i = 1;
    let info = [];

    for (let credit of credits) {
      credit.id = i;
      info.push(credit);
      i++;
    }
    return info;
  }

  const handleEditRowsModelChange = (model: GridEditRowsModel) => {
    if (Object.keys(model).length) {
      let dupplicata = rows;

      for (let info of dupplicata)
        if (model[info.id as string] !== null && model[info.id as string] !== undefined) {
          info.credits_left = model[info.id as string].credits_left.value;
          info.credits_bought = model[info.id as string].credits_bought.value;
        }
      setRows(dupplicata);
    }
  }

  const update = () => {
    APIPut<any>(REACT_APP_API_URL + "/admin/billing/credits/" + props.uuid, {
      Credits: rows,
    }).then((data) => {
      if (!data.ok) {
        setSevirity("error");
        setMessage(t("update_error_credits"));
        setOpen(true);
      } else if (data.parsedBody !== undefined) {
        setSevirity("success");
        setMessage(t("update_success_credits"));
        setOpen(true);
      }
    }, (error) => {
      console.log(error);
      setSevirity("error");
      setMessage(t("update_error_credits"));
      setOpen(true);
    });
  }

  //! DISPLAY

  const display = () => {
    if (credits !== null) {
      const columns: GridColDef[] = [
        {field: 'service_name', headerName: t("services"), width: 200},
        {field: 'credits_used', headerName: t("credits_used"), type: "number", width: 200},
        {field: 'credits_left', headerName: t("credits_left"), type: "number", width: 200, editable: true},
        {field: 'credits_bought', headerName: t("credits_bought"), type: "number", width: 200, editable: true},
      ];

      return (
        <React.Fragment>
          <SnackInformation open={open} severity={severity} message={message} close={() => {setOpen(false);}}/>
          <div style={{width: '100%', height: 500, marginBottom: '15px'}}>
            <DataGrid
              hideFooter={true}
              rows={rows}
              editMode="row"
              columns={columns}
              onEditRowsModelChange={handleEditRowsModelChange}
            />
          </div>
          <Grid container>
            <Grid item md={12} sx={{textAlign: "center", mb: 2}}>
              <Typography sx={{ mb: 1 }}>{t("update_user_info")}</Typography>
            </Grid>
            <Grid item md={12} sx={{textAlign: "center", mb: 5}}>
              <UnitagContainedButton func={update} text={t("update_credits")}/>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
    return null;
  }

  return display();
}
