import {createTheme} from '@mui/material';

export const ThemeUnitag = createTheme({
    palette: {
        primary: {
            main: "#b4c74a",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#6192bd",
            contrastText: "#ffffff",
        },
    },
    typography: {
        h1: {
            fontFamily: 'Roboto Slab',
            fontWeight: '700',
        },

        h5: {
            fontWeight: '500',
        }
    },
})
