import React from 'react';
import { Paper, Grid, Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ColorChip from '../../../../../utils/colorChip';

type MyProps = {
  qrcode: any
}

export function QrCodeBasicInfo(props: MyProps) {
  const {t} = useTranslation(['home']);

  //! DISPLAY

  const displayQRCodeChip = () => {
    switch (props.qrcode?.type) {
      case "packaging":
        return <ColorChip value="packaging" color="orange"/>;
      case "qrcode":
        switch (props.qrcode?.resolution) {
          case "static":
            return <ColorChip value="static"/>;
          case "dynamic":
            return <ColorChip color="green" value="dynamic"/>;
          case "dynamic-pro":
            return <ColorChip color="purple" value="dyn-pro"/>;
        }
        break;
      case "wifi":
        return <ColorChip value="wifi"/>;
      case "calendar":
        return <ColorChip value="event" color="red"/>;
      case "geoloc":
        return <ColorChip value="location" color="orange"/>;
      case "call":
        return <ColorChip value="call" color="green"/>;
      case "sms":
        return <ColorChip value="SMS" color="orange"/>;
      case "smsto":
        return <ColorChip value="SMS" color="orange"/>;
      case "email":
        return <ColorChip value="email" color="blue"/>;
      case "vcard":
        return <ColorChip value="vcard" color="orange"/>;
    }
    return <ColorChip value={props.qrcode?.type} color="orange"/>
  }

  const display = () => {
    return (
      <Paper variant="outlined" elevation={0} sx={{borderTop: "5px solid rgb(175,201,40)", pl: 2, minHeight: "300px"}}>
        <Grid item container alignItems={"center"} spacing={1} md={12} sx={{borderBottom: "1px solid grey",  mb: 3}}>
          <Grid item>
            <Box
              sx={{
                fontWeight: 700,
                fontSize: "1.2rem",
                py: 2,
                whiteSpace: 'nowrap',
                maxWidth: '30rem',
              }}
              component="div"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {props.qrcode.label}
            </Box>
          </Grid>
          <Grid item textAlign={"center"}>
            {displayQRCodeChip()}
          </Grid>
        </Grid>
        <Typography sx={{ fontWeight: 600, my: 1 }}>{t("Destination")}</Typography>
        { props.qrcode.full_url ?
          <Link sx={{color: "blue"}} onClick={() => {window.open(props.qrcode.full_url, "_blank")}} underline="hover">{props.qrcode.full_url}</Link>
        :
          <Typography>Missing full URL</Typography>
        }
        <Typography sx={{ fontWeight: 600, my: 1 }}>{t("Short_url")}</Typography>
        { props.qrcode.short_url ?
          <Link sx={{color: "blue"}} onClick={() => {window.open(props.qrcode.short_url, "_blank")}} underline="hover">{props.qrcode.short_url}</Link>
        :
          <Typography>Missing short URL</Typography>
        }
      </Paper>
    );
  }

  return display();
}