import React from 'react';
import { Button } from '@mui/material';

type MyProps = {
  href?: string;
  func?: any;
  text?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  icon?: any;
}

export default function UnitagContainedButton(props: MyProps) {

  //! DISPLAY

  const display = () => {
    return (
      <Button
        variant={'contained'}
        href={props.href}
        onClick={props.func}
        color="primary"
        fullWidth={props.fullWidth}
        disabled={props.disabled}
      >
        {props.icon}{props.text}
      </Button>
    );
  }

  return display();
}