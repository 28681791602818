import React from 'react';
import './App.css';

import {ThemeUnitag} from "./themes/ThemeUnitag";
import {ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Dashboard} from "./views/dashboard/dashboard";
import Navbar from "./components/navbar/navbar";
import LoginComponent from "./views/login/login";


function App() {
    return (
        <ThemeProvider theme={ThemeUnitag}>
            <BrowserRouter>
                <div className="App">
                    <Navbar/>
                    <Routes>
                        <Route path="/" element={<Dashboard/>}/>
                        <Route path="/login" element={<LoginComponent/>}/>
                    </Routes>
                </div>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
