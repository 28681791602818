import React, {useEffect} from 'react';
import {AlertColor, Grid, Typography} from '@mui/material';
import {DataGrid, GridRowsProp, GridColDef, GridEditRowsModel, GridActionsCellItem} from '@mui/x-data-grid';
import {User} from "../../../ifaces";
import {useTranslation} from 'react-i18next';
import {APIPut} from '../../../FetchBuilder';
import {SnackInformation} from '../../../utils/snackInformation';
import UnitagContainedButton from '../../../utils/unitagContainedButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';

type MyProps = {
    user: User | null;
    qrCodesTotal: number;
    organisationName: string;
}

const {REACT_APP_API_URL} = process.env;

export function UserTable(props: MyProps) {

    const {t} = useTranslation(['home']);
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    // SnackBar Props
    const [severity, setSevirity] = React.useState<AlertColor>("success");
    const [open, setOpen] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");

    //! LIFEHOOKS

    useEffect(() => {
        if (props.user !== null) {
            setRows([{
                id: 1,
                uuid: props.user.uuid,
                clean_renewal_date: checkRenewal(props.user.renewal_date),
                renewal_date: props.user.renewal_date,
                email: props.user.email,
                account_type: props.user.account_type,
                legacy_id: props.user.legacy_id,
                qrCodesTotal: dateBodyQrCodes(props.qrCodesTotal),
                organisationName: props.organisationName === "org not found" ? t("no_org_found") : props.organisationName
            }]);
        }
    }, [props]);

    //! METHODS

    const dateBodyQrCodes = (qrCodesTotal: number) => {
        if (qrCodesTotal <= 1)
            return (qrCodesTotal + " Qrcode");
        else
            return (qrCodesTotal + " Qrcodes");
    }

    const handleEditRowsModelChange = (model: GridEditRowsModel) => {
        if (model["1"])
            setRows([{
                id: 1,
                uuid: rows[0].uuid,
                clean_renewal_date: checkRenewal(model["1"].clean_renewal_date.value),
                renewal_date: model["1"].clean_renewal_date.value,
                email: rows[0].email,
                account_type: model["1"].account_type.value,
                legacy_id: rows[0].legacy_id,
                qrCodesTotal: dateBodyQrCodes(props.qrCodesTotal),
                organisationName: props.organisationName === "org not found" ? t("no_org_found") : props.organisationName
            }]);
    }

    const duplicateUserId = (param: any) => {
        navigator.clipboard.writeText(param.row.uuid)
        .then(() => {
            setSevirity("success");
            setMessage(t("copy_success"));
            setOpen(true);
        })
        .catch(err => {
            setSevirity("error");
            setMessage(t("copy_error"));
            setOpen(true);
        });
    }

    const checkRenewal = (date: any) => {
        if (date === undefined || date === null)
            return t("no_date_found");
        else
            return new Date(date);
    }

    const update = () => {
        let data: Date | null;

        if (rows[0].clean_renewal_date === "NaN/NaN/NaN")
            data = null;
        else
            data = new Date(rows[0].renewal_date);

        APIPut<any>(REACT_APP_API_URL + "/admin/user/" + rows[0].uuid, {
            renewal_date: data,
            account_type: rows[0].account_type
        }).then((data) => {
            if (data.status >= 400) {
                setSevirity("error");
                setMessage(t("update_error_user"));
                setOpen(true);
            } else if (data.parsedBody !== undefined) {
                setSevirity("success");
                setMessage(t("update_success_user"));
                setOpen(true);
            }
        }, (error) => {
            console.log(error);
            setSevirity("error");
            setMessage(t("update_error_user"));
            setOpen(true);
        });
    }

    //! DISPLAY

    const display = () => {
        if (props.user !== null) {
            const columns: GridColDef[] = [
                {
                    field: 'actions',
                    type: 'actions',
                    width: 80,
                    renderCell: (params: any) => [
                        <GridActionsCellItem
                            key={1}
                            icon={<FileCopyIcon/>}
                            label="Duplicate Id"
                            onClick={(e: any)=>{duplicateUserId(params)}}
                        />
                    ]
                },
                {field: 'uuid', headerName: "ID", width: 250},
                {field: 'email', headerName: t("email"), width: 250},
                {field: 'clean_renewal_date', headerName: t("renewal_date"), type: 'date', width: 200, editable: true},
                {
                    field: 'account_type',
                    headerName: t("account_type"),
                    type: "singleSelect",
                    valueOptions: ["free", "standard", "gold", "platinum"],
                    width: 200,
                    editable: true
                },
                {field: 'organisationName', headerName: t("organisation_name"), width: 200},
                {field: 'legacy_id', headerName: "ID legacy", width: 200},
                {field: 'qrCodesTotal', headerName: t("qrCodesTotal"), width: 200},
            ];

            return (
                <React.Fragment>
                    <SnackInformation open={open} severity={severity} message={message} close={() => {setOpen(false);}}/>
                    <div style={{width: '100%', height: 150, marginBottom: '15px'}}>
                        <DataGrid
                            onEditRowsModelChange={handleEditRowsModelChange}
                            editMode="row"
                            rowHeight={65}
                            hideFooter={true}
                            rows={rows}
                            columns={columns}/>
                    </div>
                    <Grid container>
                        <Grid item md={12} sx={{textAlign: "center", mb: 2}}>
                            <Typography sx={{ mb: 1 }}>{t("update_user_info")}</Typography>
                        </Grid>
                        <Grid item md={12} sx={{textAlign: "center", mb: 5}}>
                            <UnitagContainedButton func={update} text={t("update_user")}/>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
        return null;
    }

    return display();
}
