import {useNavigate} from "react-router-dom";
import React from "react";
import {
    Alert,
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    InputAdornment,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {Auth} from "aws-amplify";
import Background from "../../../img/background_login.png";
import {AccountCircle} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";

/*const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        }
    })
);*/


let creds = {
    username: '',
    password: '',
    passwordRepeated: '',
};

export default function Login(props: any) {
    //const classes = useStyles();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = React.useState(false);
    type AnAlert = {
        message: string;
        variant: AlertColor;
    };
    const [activeAlert, setActiveAlert] = React.useState<null | AnAlert>(null);

    const {t} = useTranslation(['login']);

    const toggleLang = (lng: string) => {
        i18next.changeLanguage(lng, (err, t) => {
            if (err) return console.log('error : ', err);
            t('key');
        });
    }

    const handleIsLoading = (isLoading: boolean) => {
        setIsLoading(isLoading);
    }

    const renderAlert = () => {
        if (activeAlert !== null && !!activeAlert.message) {
            const {message, variant} = activeAlert;
            return (
                <div>
                    <Alert variant='outlined' severity={variant}>
                        {message}
                    </Alert>
                    <br/>
                    <br/>
                </div>

            );
        }
        return null;
    }

    const handleChange = (event: any) => {
        switch (event.target.id) {
            case 'email':
                creds.username = event.target.value;
                break;
            case 'password':
                creds.password = event.target.value;
                break;
            case 'passwordRepeated':
                creds.passwordRepeated = event.target.value;
                break;
        }
    }

    const handleLoginBtn = () => {
        // Start Circular Progress on Button
        handleIsLoading(true);

        Auth.signIn(creds.username, creds.password).then(value => {
            if (value.challengeName !== undefined && value.challengeName === 'NEW_PASSWORD_REQUIRED') {
                Auth.completeNewPassword(
                    value,
                    creds.password,
                    {
                        email: creds.username,
                    }
                ).then(value => {
                    redirectByPath();
                });
            } else {
                redirectByPath();
            }
        }, reason => {
            setActiveAlert({
                variant: 'error',
                message: `${t(reason.message)}`,
            });

            handleIsLoading(false);
        });
    }

    const redirectByPath = () => {
        navigate('/');
    }

    return (
        <main>
            <div style={
                {
                    minHeight: '100vh',
                    top: '0',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    padding: 40,
                    backgroundImage: `url(${Background})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }
            }>
                <Container maxWidth='sm' style={{
                    margin: '24px auto'
                }}>
                    <Paper elevation={5} style={{borderRadius: 16, border: '1px solid #e0e0e0'}}>
                        <Box style={{padding: 24}}>


                            <Typography variant='h4' style={{marginBottom: 32}} align='center'>
                                {t('Login')}
                            </Typography>

                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleLoginBtn();
                            }}>
                                <FormControl fullWidth variant='outlined'>
                                    <TextField
                                        label={t('Mail')}
                                        id='email'
                                        placeholder={t('Your_mail')}
                                        //className={classes.textField}
                                        onChange={handleChange}
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <AccountCircle/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>

                                <br/>
                                <br/>

                                <FormControl fullWidth variant='outlined'>
                                    <TextField
                                        label={t('Password')}
                                        type='password'
                                        id='password'
                                        onChange={handleChange}
                                        placeholder={t('Your_password')}
                                        //className={classes.textField}
                                        // margin='dense'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <LockIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>

                                <br/>

                                {renderAlert()}

                                <Box style={{margin: '32px 24px 0px 24px'}}>
                                    <Button variant='contained' disableElevation color='primary'
                                            fullWidth={true} type='submit' disabled={isLoading}>
                                        {isLoading ? <CircularProgress size={21} style={{margin: 8}}/> : t('Login')}
                                    </Button>
                                </Box>
                            </form>

                        </Box>

                        <Box style={{
                            margin: '16px 1px 1px 1px',
                            backgroundColor: '#e0e0e0',
                            padding: 24,
                            borderBottomRightRadius: 14,
                            borderBottomLeftRadius: 14,
                            textAlign: 'center'
                        }}>
                            <Button variant='text' size='small' style={{color: 'grey'}} onClick={() => {
                                toggleLang('fr-FR')
                            }}>Français</Button>&nbsp;&nbsp;
                            <Button variant='text' size='small' style={{color: 'grey'}} onClick={() => {
                                toggleLang('en-EN')
                            }}>English</Button>
                        </Box>

                    </Paper>
                </Container>
            </div>
        </main>
    );
}
