import React from 'react';
import {DataGrid, GridRowsProp, GridColDef} from '@mui/x-data-grid';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

type MyProps = {
  organisations: any,
  allOrganisations: any
}

export function OrganisationsTable(props: MyProps) {

  const {t} = useTranslation(['home']);

  //! DISPLAY

  const display = () => {
    if (props.organisations !== null && props.organisations !== "org not found") {
      const columns: GridColDef[] = [
        { field: 'organisation_name', headerName: t("label"), width: 300 },
        { field: 'org_account_type', headerName: t("org_account_type"), width: 130 },
        { field: 'role', headerName: t("role"), width: 130 },
        { field: 'is_root_organisation', headerName: t("is_root_organisation"), width: 170 },
        { field: 'can_user_consume_credits', headerName: t("can_user_consume_credits"), width: 170 },
        { field: 'can_user_create_sub_org', headerName: t("can_user_create_sub_org"), width: 200 },
      ];

      console.log("props.organisations", props.organisations)
      const rows: GridRowsProp = [{...props.organisations, id: 1}];

      const allColumns: GridColDef[] = [
        { field: 'organisation_name', headerName: t("label"), width: 270 },
        { field: 'organisation_id', headerName: t("org_uuid"), width: 300 },
        { field: 'role', headerName: t("role"), width: 130 },
        { field: 'seats_left', headerName: t("seats_left"), width: 120 },
        { field: 'seats_used', headerName: t("seats_used"), width: 120 },
        { field: 'nb_users_in_org', headerName: t("nb_users_in_org"), width: 120 },
        { field: 'is_root_org', headerName: t("is_root_org"), width: 120 }
      ];

      console.log("props.allOrganisations", props.allOrganisations)
      let _allRows: GridRowsProp = props.allOrganisations;
      if (props.allOrganisations[0].member_org !== undefined && props.allOrganisations[0].member_org !== null) {
        _allRows = [props.allOrganisations[0].member_org];
      }

      const allRows: GridRowsProp = _allRows;

      return (
        <React.Fragment>
          <div style={{ width: '100%', height: 130, margin: "20px" }}>
            <Typography variant={"body1"}>{t('Belong_to')}</Typography>
            <DataGrid hideFooter={true} rows={rows} columns={columns} />
          </div>

          <div style={{width: '100%', height: 450, margin: '20px', marginTop: '75px'}}>
            <Typography variant={"body1"}>{t('All_user_orgs')}</Typography>
            <DataGrid hideFooter={true} rows={allRows} columns={allColumns} getRowId={(row) => row.organisation_id} />
          </div>
        </React.Fragment>
      );
    }
    return (<Typography align='center'>{t("no_org_found")}</Typography>);
  }

  return display();

}
