import React, {useState} from 'react';
import {Button, Grid, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function SearchUserByEmail(props: any) {
    const {t} = useTranslation(['home']);

    const [email, setEmail] = useState<string>('');

    //! METHODS

    const handleChangeEmail = (event: any) => {
        setEmail(event.currentTarget.value);
    }

    const handleSearchClick = () => {
        props.displayInfos(email);
    }

    //! DISPLAY

    const display = () => {
        return (
            <Grid container spacing={1} alignItems='center' justifyContent='center' sx={{marginTop: '25px', mr: 2}}>
                <Grid item xs={4}>
                    <TextField
                        label={'email'}
                        value={email}
                        margin={'dense'}
                        variant={'outlined'}
                        type={'text'}
                        onChange={handleChangeEmail}
                        style={{width: '100%'}}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant={'contained'}
                        onClick={handleSearchClick}
                    >
                        {t('Search')}
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return display();
}
