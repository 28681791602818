import { useEffect, useState } from "react";
import { Paper, Grid, Box } from "@mui/material";
import { User } from "../../../../../ifaces";
import { APIGetBlob } from "../../../../../FetchBuilder";

type MyProps = {
  user: User | null,
  qrcode: any
}

const {REACT_APP_API_URL} = process.env;

export function QrCodePreview(props: MyProps) {

  const [previewImg, setPreviewImg] = useState<string>("");

  //! LIFECYCLE HOOKS

  useEffect(() => {
    if (props.user && props.qrcode) {
      APIGetBlob<any>(`${REACT_APP_API_URL}/admin/qrcode/${props.user.uuid}/${props.qrcode.uuid}/preview?size=100`)
      .then((data) => {
          let src: string = "";
          if (data.ok && data.rawBlob !== undefined) {
              src = URL.createObjectURL(data.rawBlob);
          }
          setPreviewImg(src);
      });
    }
  }, [props]);

  //! Display

  const previewImage = () => {
    return (
      <img height='300px' width='300px' src={previewImg} alt={props.qrcode.label}/>
    );
  }

  const display = () => {
    return (
      <Paper variant="outlined" elevation={0} sx={{borderTop: "5px solid rgb(175,201,40)"}}>
        <Grid container spacing={3} justifyContent="space-between" alignItems="center">
          <Grid item md={12}>
            <Box display="flex" alignItems="center"
              justifyContent="center">{previewImage()}</Box>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return display();
}