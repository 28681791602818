import React, {useState, useEffect} from 'react';
import {Button, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {APIGet, APIGetBlob, APIPut} from "../../../FetchBuilder";
import {User, UrlStruct, States} from "../../../ifaces";
import {UserTable} from './userTable';
import {QrCodesInfoMigrates} from './QrCodesDisplay/QrCodesInfoMigrates';
import {QrCodeList} from './QrCodesDisplay/QrCodeList';
import {CreditsTable} from './creditsTable';
import {OrganisationsTable} from './organisationsTable';
import UnitagContainedButton from "../../../utils/unitagContainedButton";

type MyProps = {
    user: User | null;
    url: UrlStruct;
    states: Array<States>;
    handleEdit: (qrcode: any, row: number, page: number, label: string) => void;
    handleStates: (state: States, index: number) => void;
}

type UserInfo = {
    user: User | null;
    qrCodesTotal: number;
}

const {REACT_APP_API_URL} = process.env;

export function UserInformation(props: MyProps) {
    const {t} = useTranslation(['home']);

    const [user, setUser] = useState<UserInfo>({user: null, qrCodesTotal: 0});
    const [QRcodes, setQRcodes] = useState<any>(null);
    const [previews, setPreviews] = React.useState<any>({});
    const [organisations, setOrganisations] = useState<any>(null);
    const [allOrganisations, setAllOrganisations] = useState<any>(null);
    const [organisationName, setOrganisationName] = useState<string>("");
    // QRCODE DATATABLE FILTER
    const [maxRows, setMaxRows] = useState<number>(8);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [sortField, setSortField] = useState<string>("creation_date");
    const [sortOrder, setSortOrder] = useState<string>("desc");
    const [filterLabel, setFilterLabel] = useState<string>('');
    const [filterCampaign, setFilterCampaign] = useState<string>('');
    const [filterOrganisation, setFilterOrganisation] = useState<string>('');
    const [filterDestination, setFilterDestination] = useState<string>('');
    const [filterType, setFilterType] = useState<string>('');

    //! LIFEHOOKS

    useEffect(() => {
        if (props.user !== undefined && props.user !== null) {
            FetchQrCodes();
            FetchTemplates();
            FetchOrganisation();
            setMaxRows(8);
            setFilterLabel('');
            setSortField('creation_date');
            setSortOrder('desc');
            setPageNumber(1);
            setFilterCampaign('');
            setFilterOrganisation('');
            setFilterDestination('');
        } else {
            setOrganisations(null);
        }
    }, [props.user]);

    useEffect(() => {
        if (props.url.page !== 0 || props.url.row !== 0 || props.url.label !== "") {
            setMaxRows(props.url.row);
            setPageNumber(props.url.page);
            setFilterLabel(props.url.label);
        }
    }, [props.url]);

    useEffect(() => {
        const fullUrl = getUrl();
        const p = pageNumber;

        APIGet<any>(fullUrl).then((data) => {
            if (data.ok && data.parsedBody !== undefined && data.parsedBody !== null && data.parsedBody.records.length >= 0) {
                if (p === pageNumber) {
                    setQRcodes(data.parsedBody);
                    fetchPreviewQrcodes(data.parsedBody);
                }
            }
        }, (error) => {
            console.log(error);
        });
    }, [pageNumber, maxRows, filterCampaign,
        filterOrganisation, filterDestination,
        sortOrder, sortField, filterLabel, filterType]);

    //! API CALLS

    const fetchPreviewQrcodes = (qrcodes: any) => {
        for (let qrcode of qrcodes.records) {
            if ((previews[qrcode.uuid] === undefined || previews[qrcode.uuid] === "") && qrcode && qrcode.uuid !== "") {
                APIGetBlob<any>(`${REACT_APP_API_URL}/admin/qrcode/${props.user?.uuid}/${qrcode.uuid}/preview?size=100`)
                .then((data) => {
                    let src: string = "";
                    if (data.ok && data.rawBlob !== undefined) {
                        src = URL.createObjectURL(data.rawBlob);
                    }
                    setPreviews((oldPreviews: any) => ({...oldPreviews, [qrcode.uuid]: src}));
                });
            }
        }
    }

    const FetchTemplates = () => {
        APIGet<any>(REACT_APP_API_URL + "/admin/qrcodes/templates/user/" + props.user?.uuid).then((data) => {
            if (data.parsedBody !== undefined)
                console.log("Templates => ", data.parsedBody)
        }, (error) => {
            console.log(error);
        });
    }

    const FetchOrganisation = () => {
        APIGet<any>(REACT_APP_API_URL + "/admin/org/user/" + props.user?.uuid).then((data) => {
            if (data.parsedBody !== undefined) {
                setOrganisations(data.parsedBody);
                if (data.parsedBody !== "org not found")
                    setOrganisationName(data.parsedBody.organisation_name);
                else
                    setOrganisationName(data.parsedBody);
            }
        }, (error) => {
            console.log(error);
        });

        APIGet<any>(REACT_APP_API_URL + "/admin/orgs/" + props.user?.uuid).then((data) => {
            if (data.parsedBody !== undefined) {
                console.log(data.parsedBody)
                let allOrgs: any = [];
                allOrgs.push(data.parsedBody)

                if (data.parsedBody.sub_organisations !== undefined && data.parsedBody.sub_organisations !== null) {
                    console.log("inside!")
                    data.parsedBody.sub_organisations.map((suborg: any) => {
                        allOrgs.push(suborg)
                    })
                }
                setAllOrganisations(allOrgs)
                // setOrganisations(data.parsedBody);
                // if (data.parsedBody !== "org not found")
                //     setOrganisationName(data.parsedBody.organisation_name);
                // else
                //     setOrganisationName(data.parsedBody);
            }
        }, (error) => {
            console.log(error);
        });
    }

    const FetchQrCodes = () => {
        const fullUrl = getUrl();

        APIGet<any>(fullUrl).then((data) => {
            if (data.ok && data.parsedBody !== undefined && data.parsedBody !== null && data.parsedBody.records.length >= 0) {
                if ((props.user && user.user && props.user.email !== user.user.email) || user.user === null)
                    setUser({
                        user: props.user,
                        qrCodesTotal: data.parsedBody.total_records,
                    });
            }
        }, (error) => {
            console.log(error);
        });
    }

    //! METHODS

    const handleChangefilter = (maxRows: number, pageNumber: number, filterLabel: string) => {
        setMaxRows(maxRows);
        setPageNumber(pageNumber);
        setFilterLabel(filterLabel);
        props.handleStates({editing: false, creating: false, list: true}, 0);
    }

    const handleEditQrcode = (qrcode: any) => {
        props.handleEdit(qrcode, maxRows, pageNumber, filterLabel);
        props.handleStates({editing: true, creating: false, list: false}, 0);
    }

    const getUrl = () => {
        const baseURL = `${REACT_APP_API_URL}/admin/qrcodes/paginated/user/` + props.user?.uuid;
        const filterByPage = `?page=${pageNumber}`,
            filterBySortField = `${filterByPage}&sortField=${sortField}`,
            filterBySortOrder = `${filterBySortField}&sortOrder=${sortOrder}`,
            filterByLabel = `${filterBySortOrder}&filterLabel=${filterLabel}`,
            filterByDest = `${filterByLabel}&filterDestination=${filterDestination}`,
            filterByCampaign = `${filterByDest}&filterCampaign=${filterCampaign}`,
            filterByOrg = `${filterByCampaign}&filterOrg=${filterOrganisation}`,
            filterByType = `${filterByOrg}&filterType=${filterType}`,
            rowsNumber = `${filterByType}&maxRows=${maxRows}`;
        const fullUrl = `${baseURL}${rowsNumber}`;
        return fullUrl;
    }

    const handleSeeList = (
        templates: boolean,
        Organisations: boolean,
        Credits: boolean,
        Qrcodes: boolean) => {
        if (Qrcodes) {
            props.handleStates({editing: false, creating: false, list: true}, 0);
        } else if (Credits) {
            props.handleStates({editing: false, creating: false, list: true}, 1);
        } else if (Organisations) {
            props.handleStates({editing: false, creating: false, list: true}, 2);
        } else if (templates) {
            props.handleStates({editing: false, creating: false, list: true}, 3);
        }
    }

    const handleChangeType = (type: string) => {
        setFilterType(type)
    }

    //! DISPLAY

    const displayButtons = () => {
        if (props.user !== null)
            return (
                <Grid container spacing={1} item md={12} style={{marginTop: '50px', marginBottom: '25px'}}>
                    <Grid item md={3}>
                        <Button
                            style={{width: '100%'}}
                            variant={'contained'}
                            onClick={() => {
                                handleSeeList(false, false, false, true);
                            }}
                            disabled={props.states[0].creating || props.states[0].editing || props.states[0].list}
                        >
                            {t('See_Qrcode_List')}
                        </Button>
                    </Grid>
                    <Grid item md={3}>
                        <Button
                            style={{width: '100%'}}
                            variant={'contained'}
                            onClick={() => {
                                handleSeeList(false, false, true, false);
                            }}
                            disabled={props.states[1].creating || props.states[1].editing || props.states[1].list}
                        >
                            {t('See_Credits')}
                        </Button>
                    </Grid>
                    <Grid item md={3}>
                        <Button
                            style={{width: '100%'}}
                            variant={'contained'}
                            onClick={() => {
                                handleSeeList(false, true, false, false);
                            }}
                            disabled={props.states[2].creating || props.states[2].editing || props.states[2].list}
                        >
                            {t('See_Organisation')}
                        </Button>
                    </Grid>
                    <Grid item md={3}>
                        <Button
                            style={{width: '100%'}}
                            variant={'contained'}
                            onClick={() => {
                                handleSeeList(true, false, false, false);
                            }}
                            disabled={props.states[3].creating || props.states[3].editing || props.states[3].list}
                        >
                            {t('See_Templates')}
                        </Button>
                    </Grid>
                </Grid>
            );
        return null;
    }

    const displayDatatable = () => {
        if (props.states[0].creating || props.states[0].editing || props.states[0].list)
            return (
                <React.Fragment>
                    <QrCodesInfoMigrates user={user.user} handleChange={handleChangeType} QRcodes={QRcodes}/>
                    <QrCodeList user={user.user} QRcodes={QRcodes} previews={previews} maxRows={maxRows} pageNumber={pageNumber} filterLabel={filterLabel} 
                        handlechange={handleChangefilter} handleEdit={handleEditQrcode} states={props.states[0]}/>
                </React.Fragment>
            );
        else if (props.states[1].creating || props.states[1].editing || props.states[1].list)
            return <CreditsTable uuid={user.user?.uuid}/>;
        else if (props.states[2].creating || props.states[2].editing || props.states[2].list)
            return <OrganisationsTable organisations={organisations} allOrganisations={allOrganisations}/>;
        else if (props.states[3].creating || props.states[3].editing || props.states[3].list)
            return (
                <React.Fragment/>
            );

        return null;
    }

    const unblank = () => {
        APIPut<any>(REACT_APP_API_URL + "/admin/unblank-account", {
            uuid: user.user?.uuid,
        }).then((data) => {
            if (!data.ok) {
                console.log(data.parsedBody);
            }
        }, (error) => {
            console.log(error);
        });
    }

    const display = () => {
        return (
            <React.Fragment>
                <Grid container style={{textAlign: 'center', alignContent: 'center'}}>
                <Grid container md={12}>
                    <Grid item xs={2} style={{paddingTop: '6px'}}>
                        <Typography sx={{ mb: 1 }}>La page crédit est blanche ? Déblanchir :</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <UnitagContainedButton func={unblank} text={'Déblanchir'}/>
                    </Grid>
                </Grid>
                </Grid>
                {displayButtons()}
                <UserTable organisationName={organisationName} user={user.user} qrCodesTotal={user.qrCodesTotal}/>
                {displayDatatable()}
            </React.Fragment>
        );
    }

    return display();
}
